<template>
  <DashboardAdminTemplate>
    <div
      class="pd-x-5 pd-b-3 mg-b-1 pd-b-1"
      id="statemamt"
      v-loading.fullscreen.lock="fullLoading"
      :fullscreen="true"
      :element-loading-text="'กำลัง Sync ข้อมูล DBD รายบุคคล'"
      v-if="companyStatememtInfo"
    >
      <div class="is-flex js-between ai-center">
        <h2 class="mg-r-5">ข้อมูลงบการเงิน</h2>
        <div>
          <el-button
            type="primary"
            style="width: 130px"
            class="mg-x-5"
            @click="sysDbdByUserId($route.params.id)"
            >DBD Sync</el-button
          >
        </div>
      </div>

      <!-- <pre>
  {{ companyStatememtInfo }}
</pre
      > -->

      <div v-if="companyStatememtInfo.companyDBD.juristicId == ''">
        <p class="font-32 mg-t-0 mg-b-5">
          {{ companyStatememtInfo.companyName }}
          <small>{{
            typeof companyStatememtInfo.companyNameEN == "undefined"
              ? ""
              : "ใส่ค่า"
          }}</small>
        </p>
        <p class="font-24 mg-t-0">
          เลขทะเบียนนิติบุคคล : {{ companyStatememtInfo.corporateNumber }}
        </p>
      </div>

      <div v-else>
        <p class="font-32 mg-t-0 mg-b-5">
          {{ companyStatememtInfo.companyDBD.juristicName_TH }}
          <small>({{ companyStatememtInfo.companyDBD.juristicName_EN }})</small>
        </p>
        <p class="font-24 mg-t-0">
          เลขทะเบียนนิติบุคคล : {{ companyStatememtInfo.companyDBD.juristicId }}
        </p>
      </div>
      <div class="text-right mg-b-5 text-grey-light font-16">
        ข้อมูลจาก DBD อัปเดตล่าสุดเมื่อ
        <span v-if="companyStatememtInfo.lastSyncDBD">{{
          companyStatememtInfo.lastSyncDBD | dateTimeTh
        }}</span>
        <span v-else>-</span>
      </div>
      <div v-if="financeDBDInfo">
        <!-- <pre>
          {{ financeDBDInfo }}
        </pre> -->
        <el-collapse v-model="activeNames" @change="handleChange">
          <div>
            <el-collapse-item name="1">
              <template slot="title">
                <p class="font-24">งบแสดงฐานะทางการเงิน</p>
              </template>
              <div class="data-info">
                <p class="font-28 text-center">งบแสดงฐานะทางการเงิน</p>
                <p class="font-22 text-grey text-center">
                  ข้อมูลปีงบการเงิน {{ yearNow1 | yearTh }} -
                  {{ yearNow5 | yearTh }}
                </p>
              </div>
              <div class="data-table">
                <table class="table table-bordered" id="table-data">
                  <thead>
                    <tr class="thead-y">
                      <th class="th-style">หน่วย : บาท</th>
                      <th>{{ yearNow1 | yearTh }}</th>
                      <th>{{ yearNow2 | yearTh }}</th>
                      <th>{{ yearNow3 | yearTh }}</th>
                      <th>{{ yearNow4 | yearTh }}</th>
                      <th>{{ yearNow5 | yearTh }}</th>
                    </tr>
                    <tr class="thead-m">
                      <th class="th-style"></th>
                      <th>จำนวนเงิน</th>
                      <th>จำนวนเงิน</th>
                      <th>จำนวนเงิน</th>
                      <th>จำนวนเงิน</th>
                      <th>จำนวนเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" class="th-style">สินทรัพย์หมุนเวียน</th>
                      <td>
                        {{
                          typeof findYearDBD1data.totalCurrentAsset ==
                            "undefined" ||
                          findYearDBD1data.totalCurrentAsset == null
                            ? "N/A"
                            : findYearDBD1data.totalCurrentAsset | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.totalCurrentAsset ==
                            "undefined" ||
                          findYearDBD2data.totalCurrentAsset == null
                            ? "-"
                            : findYearDBD2data.totalCurrentAsset | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.totalCurrentAsset ==
                            "undefined" ||
                          findYearDBD3data.totalCurrentAsset == null
                            ? "-"
                            : findYearDBD3data.totalCurrentAsset | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.totalCurrentAsset ==
                            "undefined" ||
                          findYearDBD4data.totalCurrentAsset == null
                            ? "-"
                            : findYearDBD4data.totalCurrentAsset | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.totalCurrentAsset ==
                            "undefined" ||
                          findYearDBD5data.totalCurrentAsset == null
                            ? "-"
                            : findYearDBD5data.totalCurrentAsset | commaValue
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">ลูกหนี้การค้า</th>
                      <td>
                        {{
                          typeof findYearDBD1data.accountReceivable ==
                            "undefined" ||
                          findYearDBD1data.accountReceivable == null
                            ? "N/A"
                            : findYearDBD1data.accountReceivable | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.accountReceivable ==
                            "undefined" ||
                          findYearDBD2data.accountReceivable == null
                            ? "N/A"
                            : findYearDBD2data.accountReceivable | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.accountReceivable ==
                            "undefined" ||
                          findYearDBD3data.accountReceivable == null
                            ? "N/A"
                            : findYearDBD3data.accountReceivable | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.accountReceivable ==
                            "undefined" ||
                          findYearDBD4data.accountReceivable == null
                            ? "N/A"
                            : findYearDBD4data.accountReceivable | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.accountReceivable ==
                            "undefined" ||
                          findYearDBD5data.accountReceivable == null
                            ? "N/A"
                            : findYearDBD5data.accountReceivable | commaValue
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">สินค้าคงคลัง</th>
                      <td>
                        {{
                          typeof findYearDBD1data.inventory == "undefined" ||
                          findYearDBD1data.inventory == null
                            ? "N/A"
                            : findYearDBD1data.inventory | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.inventory == "undefined" ||
                          findYearDBD2data.inventory == null
                            ? "N/A"
                            : findYearDBD2data.inventory | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.inventory == "undefined" ||
                          findYearDBD3data.inventory == null
                            ? "N/A"
                            : findYearDBD3data.inventory | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.inventory == "undefined" ||
                          findYearDBD4data.inventory == null
                            ? "N/A"
                            : findYearDBD4data.inventory | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.inventory == "undefined" ||
                          findYearDBD5data.inventory == null
                            ? "N/A"
                            : findYearDBD5data.inventory | commaValue
                        }}
                      </td>
                    </tr>
                    <tr class="throw">
                      <th scope="row" class="throw">สินทรัพย์รวม</th>
                      <td class="throw">
                        {{
                          typeof findYearDBD1data.totalAsset == "undefined" ||
                          findYearDBD1data.totalAsset == null
                            ? "N/A"
                            : findYearDBD1data.totalAsset | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          typeof findYearDBD2data.totalAsset == "undefined" ||
                          findYearDBD2data.totalAsset == null
                            ? "N/A"
                            : findYearDBD2data.totalAsset | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          typeof findYearDBD3data.totalAsset == "undefined" ||
                          findYearDBD3data.totalAsset == null
                            ? "N/A"
                            : findYearDBD3data.totalAsset | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          typeof findYearDBD4data.totalAsset == "undefined" ||
                          findYearDBD4data.totalAsset == null
                            ? "N/A"
                            : findYearDBD4data.totalAsset | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          typeof findYearDBD5data.totalAsset == "undefined" ||
                          findYearDBD5data.totalAsset == null
                            ? "N/A"
                            : findYearDBD5data.totalAsset | commaValue
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">หนี้สินหมุนเวียน</th>
                      <td>
                        {{
                          typeof findYearDBD1data.currentLiabilities ==
                            "undefined" ||
                          findYearDBD1data.currentLiabilities == null
                            ? "-"
                            : findYearDBD1data.currentLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.currentLiabilities ==
                            "undefined" ||
                          findYearDBD2data.currentLiabilities == null
                            ? "-"
                            : findYearDBD2data.currentLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.currentLiabilities ==
                            "undefined" ||
                          findYearDBD3data.currentLiabilities == null
                            ? "-"
                            : findYearDBD3data.currentLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.currentLiabilities ==
                            "undefined" ||
                          findYearDBD4data.currentLiabilities == null
                            ? "-"
                            : findYearDBD4data.currentLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.currentLiabilities ==
                            "undefined" ||
                          findYearDBD5data.currentLiabilities == null
                            ? "-"
                            : findYearDBD5data.currentLiabilities | commaValue
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">หนี้สินรวม</th>
                      <td>
                        {{
                          typeof findYearDBD1data.totalLiabilities ==
                            "undefined" ||
                          findYearDBD1data.totalLiabilities == null
                            ? "-"
                            : findYearDBD1data.totalLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.totalLiabilities ==
                            "undefined" ||
                          findYearDBD2data.totalLiabilities == null
                            ? "-"
                            : findYearDBD2data.totalLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.totalLiabilities ==
                            "undefined" ||
                          findYearDBD3data.totalLiabilities == null
                            ? "-"
                            : findYearDBD3data.totalLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.totalLiabilities ==
                            "undefined" ||
                          findYearDBD4data.totalLiabilities == null
                            ? "-"
                            : findYearDBD4data.totalLiabilities | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.totalLiabilities ==
                            "undefined" ||
                          findYearDBD5data.totalLiabilities == null
                            ? "-"
                            : findYearDBD5data.totalLiabilities | commaValue
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">หนี้สินของผู้ถือหุ้น</th>
                      <td>
                        {{
                          typeof findYearDBD1data.totalLiabilitiesShareholderEquity ==
                            "undefined" ||
                          findYearDBD1data.totalLiabilitiesShareholderEquity ==
                            null
                            ? "N/A"
                            : findYearDBD1data.totalLiabilitiesShareholderEquity
                              | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.totalLiabilitiesShareholderEquity ==
                            "undefined" ||
                          findYearDBD2data.totalLiabilitiesShareholderEquity ==
                            null
                            ? "N/A"
                            : findYearDBD2data.totalLiabilitiesShareholderEquity
                              | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.totalLiabilitiesShareholderEquity ==
                            "undefined" ||
                          findYearDBD3data.totalLiabilitiesShareholderEquity ==
                            null
                            ? "N/A"
                            : findYearDBD3data.totalLiabilitiesShareholderEquity
                              | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.totalLiabilitiesShareholderEquity ==
                            "undefined" ||
                          findYearDBD4data.totalLiabilitiesShareholderEquity ==
                            null
                            ? "N/A"
                            : findYearDBD4data.totalLiabilitiesShareholderEquity
                              | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.totalLiabilitiesShareholderEquity ==
                            "undefined" ||
                          findYearDBD5data.totalLiabilitiesShareholderEquity ==
                            null
                            ? "N/A"
                            : findYearDBD5data.totalLiabilitiesShareholderEquity
                              | commaValue
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">สัดส่วนของผู้ถือหุ้น</th>
                      <td>
                        {{
                          typeof findYearDBD1data.shareholderEquity ==
                            "undefined" ||
                          findYearDBD1data.shareholderEquity == null
                            ? "-"
                            : findYearDBD1data.shareholderEquity | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.shareholderEquity ==
                            "undefined" ||
                          findYearDBD2data.shareholderEquity == null
                            ? "-"
                            : findYearDBD2data.shareholderEquity | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.shareholderEquity ==
                            "undefined" ||
                          findYearDBD3data.shareholderEquity == null
                            ? "-"
                            : findYearDBD3data.shareholderEquity | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.shareholderEquity ==
                            "undefined" ||
                          findYearDBD4data.shareholderEquity == null
                            ? "-"
                            : findYearDBD4data.shareholderEquity | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.shareholderEquity ==
                            "undefined" ||
                          findYearDBD5data.shareholderEquity == null
                            ? "-"
                            : findYearDBD5data.shareholderEquity | commaValue
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row" class="throw">
                        หนี้สินรวมและสัดส่วนของผู้ถือหุ้น
                      </th>
                      <td class="throw">
                        {{
                          Sum(
                            findYearDBD1data.totalLiabilities,
                            findYearDBD1data.shareholderEquity
                          ) | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          Sum(
                            findYearDBD2data.totalLiabilities,
                            findYearDBD2data.shareholderEquity
                          ) | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          Sum(
                            findYearDBD3data.totalLiabilities,
                            findYearDBD3data.shareholderEquity
                          ) | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          Sum(
                            findYearDBD4data.totalLiabilities,
                            findYearDBD4data.shareholderEquity
                          ) | commaValue
                        }}
                      </td>
                      <td class="throw">
                        {{
                          Sum(
                            findYearDBD5data.totalLiabilities,
                            findYearDBD5data.shareholderEquity
                          ) | commaValue
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">แบ่งปันทั้งหมด</th>
                      <td>
                        {{
                          typeof findYearDBD1data.totalShare == "undefined" ||
                          findYearDBD1data.totalShare == null
                            ? "N/A"
                            : findYearDBD1data.totalShare | commaValue
                        }}
                      </td>

                      <td>
                        {{
                          typeof findYearDBD2data.totalShare == "undefined" ||
                          findYearDBD2data.totalShare == null
                            ? "N/A"
                            : findYearDBD2data.totalShare | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.totalShare == "undefined" ||
                          findYearDBD3data.totalShare == null
                            ? "N/A"
                            : findYearDBD3data.totalShare | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.totalShare == "undefined" ||
                          findYearDBD4data.totalShare == null
                            ? "N/A"
                            : findYearDBD4data.totalShare | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.totalShare == "undefined" ||
                          findYearDBD5data.totalShare == null
                            ? "N/A"
                            : findYearDBD5data.totalShare | commaValue
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">ค่าใช้จ่ายของผู้ดูแล</th>
                      <td>
                        {{
                          typeof findYearDBD1data.adminExpenses ==
                            "undefined" ||
                          findYearDBD1data.adminExpenses == null
                            ? "N/A"
                            : findYearDBD1data.adminExpenses | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD2data.adminExpenses ==
                            "undefined" ||
                          findYearDBD2data.adminExpenses == null
                            ? "N/A"
                            : findYearDBD2data.adminExpenses | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD3data.adminExpenses ==
                            "undefined" ||
                          findYearDBD3data.adminExpenses == null
                            ? "N/A"
                            : findYearDBD3data.adminExpenses | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD4data.adminExpenses ==
                            "undefined" ||
                          findYearDBD4data.adminExpenses == null
                            ? "N/A"
                            : findYearDBD4data.adminExpenses | commaValue
                        }}
                      </td>
                      <td>
                        {{
                          typeof findYearDBD5data.adminExpenses ==
                            "undefined" ||
                          findYearDBD5data.adminExpenses == null
                            ? "N/A"
                            : findYearDBD5data.adminExpenses | commaValue
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-collapse-item>
          </div>
          <el-collapse-item name="2">
            <template slot="title">
              <p class="font-24">งบกำไรขาดทุน</p>
            </template>
            <div>
              <p class="font-28 text-center">งบกำไรขาดทุน</p>
              <p class="font-22 text-grey text-center">
                ข้อมูลปีงบการเงิน {{ yearNow1 | yearTh }} -
                {{ yearNow5 | yearTh }}
              </p>
            </div>
            <div class="data-table">
              <table class="table table-bordered" id="table-data">
                <thead>
                  <tr class="thead-y">
                    <th class="th-style">หน่วย : บาท</th>
                    <th>{{ yearNow1 | yearTh }}</th>
                    <th>{{ yearNow2 | yearTh }}</th>
                    <th>{{ yearNow3 | yearTh }}</th>
                    <th>{{ yearNow4 | yearTh }}</th>
                    <th>{{ yearNow5 | yearTh }}</th>
                  </tr>
                  <tr class="thead-m">
                    <th></th>
                    <th>จำนวนเงิน</th>
                    <th>จำนวนเงิน</th>
                    <th>จำนวนเงิน</th>
                    <th>จำนวนเงิน</th>
                    <th>จำนวนเงิน</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">ต้นทุนขาย</th>
                    <td>
                      {{
                        typeof findYearDBD1data.costOfGoodsSold ==
                          "undefined" ||
                        findYearDBD1data.costOfGoodsSold == null
                          ? "-"
                          : findYearDBD1data.costOfGoodsSold | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD2data.costOfGoodsSold ==
                          "undefined" ||
                        findYearDBD2data.costOfGoodsSold == null
                          ? "-"
                          : findYearDBD2data.costOfGoodsSold | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD3data.costOfGoodsSold ==
                          "undefined" ||
                        findYearDBD3data.costOfGoodsSold == null
                          ? "-"
                          : findYearDBD3data.costOfGoodsSold | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD4data.costOfGoodsSold ==
                          "undefined" ||
                        findYearDBD4data.costOfGoodsSold == null
                          ? "-"
                          : findYearDBD4data.costOfGoodsSold | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD5data.costOfGoodsSold ==
                          "undefined" ||
                        findYearDBD5data.costOfGoodsSold == null
                          ? "-"
                          : findYearDBD5data.costOfGoodsSold | commaValue
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">รับต่อหุ้น</th>
                    <td>
                      {{
                        typeof findYearDBD1data.earningPerShare ==
                          "undefined" ||
                        findYearDBD1data.earningPerShare == null
                          ? "-"
                          : findYearDBD1data.earningPerShare | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD2data.earningPerShare ==
                          "undefined" ||
                        findYearDBD2data.earningPerShare == null
                          ? "-"
                          : findYearDBD2data.earningPerShare | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD3data.earningPerShare ==
                          "undefined" ||
                        findYearDBD3data.earningPerShare == null
                          ? "-"
                          : findYearDBD3data.earningPerShare | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD4data.earningPerShare ==
                          "undefined" ||
                        findYearDBD4data.earningPerShare == null
                          ? "-"
                          : findYearDBD4data.earningPerShare | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD5data.earningPerShare ==
                          "undefined" ||
                        findYearDBD5data.earningPerShare == null
                          ? "-"
                          : findYearDBD5data.earningPerShare | commaValue
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">ภาษีเงินได้</th>
                    <td>
                      {{
                        typeof findYearDBD1data.incomeTax == "undefined" ||
                        findYearDBD1data.incomeTax == null
                          ? "-"
                          : findYearDBD1data.incomeTax | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD2data.incomeTax == "undefined" ||
                        findYearDBD2data.incomeTax == null
                          ? "-"
                          : findYearDBD2data.incomeTax | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD3data.incomeTax == "undefined" ||
                        findYearDBD3data.incomeTax == null
                          ? "-"
                          : findYearDBD3data.incomeTax | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD4data.incomeTax == "undefined" ||
                        findYearDBD4data.incomeTax == null
                          ? "-"
                          : findYearDBD4data.incomeTax | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD5data.incomeTax == "undefined" ||
                        findYearDBD5data.incomeTax == null
                          ? "-"
                          : findYearDBD5data.incomeTax | commaValue
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">ดอกเบี้ยจ่าย</th>
                    <td>
                      {{
                        typeof findYearDBD1data.interestExpenses ==
                          "undefined" ||
                        findYearDBD1data.interestExpenses == null
                          ? "-"
                          : findYearDBD1data.interestExpenses | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD2data.interestExpenses ==
                          "undefined" ||
                        findYearDBD2data.interestExpenses == null
                          ? "-"
                          : findYearDBD2data.interestExpenses | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD3data.interestExpenses ==
                          "undefined" ||
                        findYearDBD3data.interestExpenses == null
                          ? "-"
                          : findYearDBD3data.interestExpenses | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD4data.interestExpenses ==
                          "undefined" ||
                        findYearDBD4data.interestExpenses == null
                          ? "-"
                          : findYearDBD4data.interestExpenses | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD5data.interestExpenses ==
                          "undefined" ||
                        findYearDBD5data.interestExpenses == null
                          ? "-"
                          : findYearDBD5data.interestExpenses | commaValue
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">รายได้จากการขาย</th>
                    <td>
                      {{
                        typeof findYearDBD1data.saleRevenue == "undefined" ||
                        findYearDBD1data.saleRevenue == null
                          ? "-"
                          : findYearDBD1data.saleRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD2data.saleRevenue == "undefined" ||
                        findYearDBD2data.saleRevenue == null
                          ? "-"
                          : findYearDBD2data.saleRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD3data.saleRevenue == "undefined" ||
                        findYearDBD3data.saleRevenue == null
                          ? "-"
                          : findYearDBD3data.saleRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD4data.saleRevenue == "undefined" ||
                        findYearDBD4data.saleRevenue == null
                          ? "-"
                          : findYearDBD4data.saleRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD5data.saleRevenue == "undefined" ||
                        findYearDBD5data.saleRevenue == null
                          ? "-"
                          : findYearDBD5data.saleRevenue | commaValue
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">รายได้รวม</th>
                    <td>
                      {{
                        typeof findYearDBD1data.totalRevenue == "undefined" ||
                        findYearDBD1data.totalRevenue == null
                          ? "-"
                          : findYearDBD1data.totalRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD2data.totalRevenue == "undefined" ||
                        findYearDBD2data.totalRevenue == null
                          ? "-"
                          : findYearDBD2data.totalRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD3data.totalRevenue == "undefined" ||
                        findYearDBD3data.totalRevenue == null
                          ? "-"
                          : findYearDBD3data.totalRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD4data.totalRevenue == "undefined" ||
                        findYearDBD4data.totalRevenue == null
                          ? "-"
                          : findYearDBD4data.totalRevenue | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        typeof findYearDBD5data.totalRevenue == "undefined" ||
                        findYearDBD5data.totalRevenue == null
                          ? "-"
                          : findYearDBD5data.totalRevenue | commaValue
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">กำไร (ขาดทุน) ก่อนภาษี</th>
                    <td>
                      <!-- {{
                        ProfitALndoss(
                          findYearDBD1data.totalRevenue,
                          findYearDBD1data.costOfGoodsSold,
                          findYearDBD1data.saleRevenue,
                          findYearDBD1data.interestExpenses
                        ) | commaValue
                      }} -->

                      {{
                        Sum(
                          findYearDBD1data.incomeTax,
                          findYearDBD1data.netProfit
                        ) | commaValue
                      }}
                    </td>
                    <td>
                      <!-- {{
                        ProfitALndoss(
                          findYearDBD2data.totalRevenue,
                          findYearDBD2data.costOfGoodsSold,
                          findYearDBD2data.saleRevenue,
                          findYearDBD2data.interestExpenses
                        ) | commaValue
                      }} -->
                      {{
                        Sum(
                          findYearDBD2data.incomeTax,
                          findYearDBD2data.netProfit
                        ) | commaValue
                      }}
                    </td>
                    <td>
                      <!-- {{
                        ProfitALndoss(
                          findYearDBD3data.totalRevenue,
                          findYearDBD3data.costOfGoodsSold,
                          findYearDBD3data.saleRevenue,
                          findYearDBD3data.interestExpenses
                        ) | commaValue
                      }} -->
                      {{
                        Sum(
                          findYearDBD3data.incomeTax,
                          findYearDBD3data.netProfit
                        ) | commaValue
                      }}
                    </td>
                    <td>
                      <!-- {{
                        ProfitALndoss(
                          findYearDBD4data.totalRevenue,
                          findYearDBD4data.costOfGoodsSold,
                          findYearDBD4data.saleRevenue,
                          findYearDBD4data.interestExpenses
                        ) | commaValue
                      }} -->
                      {{
                        Sum(
                          findYearDBD4data.incomeTax,
                          findYearDBD4data.netProfit
                        ) | commaValue
                      }}
                    </td>
                    <td>
                      {{
                        Sum(
                          findYearDBD5data.incomeTax,
                          findYearDBD5data.netProfit
                        ) | commaValue
                      }}
                    </td>
                  </tr>
                  <tr class="throw">
                    <th scope="row" class="throw">กำไร(ขาดทุน) สุทธิ</th>
                    <td class="throw">
                      {{
                        typeof findYearDBD1data.netProfit == "undefined" ||
                        findYearDBD1data.netProfit == null
                          ? "-"
                          : findYearDBD1data.netProfit | commaValue
                      }}
                    </td>
                    <td class="throw">
                      {{
                        typeof findYearDBD2data.netProfit == "undefined" ||
                        findYearDBD2data.netProfit == null
                          ? "-"
                          : findYearDBD2data.netProfit | commaValue
                      }}
                    </td>
                    <td class="throw">
                      {{
                        typeof findYearDBD3data.netProfit == "undefined" ||
                        findYearDBD3data.netProfit == null
                          ? "-"
                          : findYearDBD3data.netProfit | commaValue
                      }}
                    </td>
                    <td class="throw">
                      {{
                        typeof findYearDBD4data.netProfit == "undefined" ||
                        findYearDBD4data.netProfit == null
                          ? "-"
                          : findYearDBD4data.netProfit | commaValue
                      }}
                    </td>
                    <td class="throw">
                      {{
                        typeof findYearDBD5data.netProfit == "undefined" ||
                        findYearDBD5data.netProfit == null
                          ? "-"
                          : findYearDBD5data.netProfit | commaValue
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div v-else>
        <el-collapse v-model="activeNames" @change="handleChange">
          <div>
            <el-collapse-item name="1">
              <template slot="title">
                <p class="font-24">งบแสดงฐานะทางการเงิน</p>
              </template>
              <div class="data-info">
                <p class="font-28 text-center">
                  ไม่พบข้อมูล งบแสดงฐานะทางการเงิน
                </p>
              </div>
            </el-collapse-item>
          </div>
          <el-collapse-item name="2">
            <template slot="title">
              <p class="font-24">งบกำไรขาดทุน</p>
            </template>
            <div>
              <p class="font-28 text-center">ไม่พบข้อมูล งบกำไรขาดทุน</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import moment from "moment";
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardAdminTemplate
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getCompanyStatememt();
    //this.findYearDBD();
  },
  data() {
    return {
      dataYear1: moment().add(-5, "y"),
      dataYear2: moment().add(-4, "y"),
      dataYear3: moment().add(-3, "y"),
      dataYear4: moment().add(-2, "y"),
      dataYear5: moment().add(-1, "y"),
      findYearDBD1data: "",
      findYearDBD2data: "",
      findYearDBD3data: "",
      findYearDBD4data: "",
      findYearDBD5data: "",
      financeDBDInfo: null,
      companyStatememtInfo: "",
      activeNames: ["1", "2"],
      fullLoading: false,
      yearNow1: moment().add(-5, "y"),
      yearNow2: moment().add(-4, "y"),
      yearNow3: moment().add(-3, "y"),
      yearNow4: moment().add(-2, "y"),
      yearNow5: moment().add(-1, "y")
    };
  },
  methods: {
    sysDbdByUserId(id) {
      this.fullLoading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`system/admin/request/dbd/juristicId`, { userId: id })
        .then(result => {
          if (result.data.success) {
            if (result.data.dbd) {
              this.$message({
                message: "Sync ข้อมูลสำเร็จ",
                type: "success"
              });
            } else {
              this.$message.error({
                message: "เลขนิติบุคคลนี้ไม่มีข้อมูลตาม DBD"
              });
            }
            this.getCompanyStatememt();
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.fullLoading = false;
        });
    },
    handleChange(val) {
      // console.log(val);
    },

    async getCompanyStatememt() {
      // console.log(this.companyId, "companyId");

      // ====
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.get(
        `information/${this.$route.params.id}/${this.$route.params.companyId}`
      )
        .then(res => {
          if (res.data.success) {
            this.companyStatememtInfo = res.data.obj;
            // this.financeDBDInfo = res.data.obj.finance_dbd;
            if (res.data.obj.finance_dbd != "") {
              // console.log(res.data.obj.finance_dbd, "res.data.obj.finance_dbd");
              this.financeDBDInfo = res.data.obj.finance_dbd;
              this.findYearDBD1();
              this.findYearDBD2();
              this.findYearDBD3();
              this.findYearDBD4();
              this.findYearDBD5();
            }
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        })
        .finally(() => {
          // this.loadingConfirm = false;
        });
    },

    findYearDBD1() {
      if (this.financeDBDInfo != "") {
        let Year1 = this.dataYear1.add(543, "y").format("YYYY");

        let DBD1 = this.financeDBDInfo.filter(
          item => item.statementYear == Year1
        );
        if (DBD1.length > 0) {
          this.findYearDBD1data = DBD1[0];
        }
      }
    },
    findYearDBD2() {
      if (this.financeDBDInfo != "") {
        let Year2 = this.dataYear2.add(543, "y").format("YYYY");

        let DBD2 = this.financeDBDInfo.filter(
          item => item.statementYear == Year2
        );
        if (DBD2.length > 0) {
          this.findYearDBD2data = DBD2[0];
        }
      }
    },
    findYearDBD3() {
      if (this.financeDBDInfo != "") {
        let Year3 = this.dataYear3.add(543, "y").format("YYYY");

        let DBD3 = this.financeDBDInfo.filter(
          item => item.statementYear == Year3
        );
        if (DBD3.length > 0) {
          this.findYearDBD3data = DBD3[0];
        }
      }
    },
    findYearDBD4() {
      if (this.financeDBDInfo != "") {
        let Year4 = this.dataYear4.add(543, "y").format("YYYY");

        let DBD4 = this.financeDBDInfo.filter(
          item => item.statementYear == Year4
        );
        if (DBD4.length > 0) {
          this.findYearDBD4data = DBD4[0];
        }
      }
    },
    findYearDBD5() {
      if (this.financeDBDInfo != "") {
        let Year5 = this.dataYear5.add(543, "y").format("YYYY");

        let DBD5 = this.financeDBDInfo.filter(
          item => item.statementYear == Year5
        );
        if (DBD5.length > 0) {
          this.findYearDBD5data = DBD5[0];
        }
      }
    },

    Sum(val1, val2) {
      if (
        typeof val1 == "undefined" ||
        typeof val2 == "undefined" ||
        val1 == "nu" ||
        val2 == "nu"
      ) {
        return "-";
      } else {
        return (Number(val1) + Number(val2)).toFixed(2);
      }
    },

    ProfitALndoss(val1, val2, val3, val4) {
      if (
        typeof val1 == "undefined" ||
        typeof val2 == "undefined" ||
        typeof val3 == "undefined" ||
        typeof val4 == "undefined"
      ) {
        return "-";
      } else {
        return (
          Number(val1) -
          Number(val2) -
          Number(val3) -
          Number(val4)
        ).toFixed(2);
      }
    },

    netProfit(val1, val2, val3, val4, val5) {
      if (
        typeof val1 == "undefined" ||
        typeof val2 == "undefined" ||
        typeof val3 == "undefined" ||
        typeof val4 == "undefined" ||
        typeof val5 == "undefined"
      ) {
        return "-";
      } else {
        let Profit = Number(val1) - Number(val2) - Number(val3) - Number(val4);
        let net = Profit - Number(val5);

        return net.toFixed(2);
      }
    }
  }
};
</script>
